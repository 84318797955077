.preschool-page{
    background-color: white;
    &__h2{
        display: block;
        text-align: center;
        margin: 1.8rem 0 1.2rem 0;
        font-size: 1.5rem;
        color: var(--green);
        &--right{
            margin-left: 16rem;
        }
        &--left{
            margin-right: 19rem;
        }
    }
    &__body{
        padding: 0 23rem;
        padding-bottom: 2rem;
    }
    &__section{
        margin-top: 3rem;
        //height: 22rem;
        // &--two{
        //     height: 21rem;
        // }
    }
    &__paragraph{
        font-size: 1.2rem;
        margin-bottom: 1.4rem;
        text-align: justify;
        padding: 0 1rem;
    }
    &__greating{
        display: block;
        text-align: center;
        margin-top: 2rem;
        color: var(--green);
        font-size: 1.8rem;
    }
    &__image{
        max-height: 11rem;
        //max-height: 100%;
        float: left;
        padding-right: 1.5rem;
        &--right{
            max-height: 23rem;
            float: right;
            padding-right: 0;
            padding-left: 1.5rem;
            //margin-bottom: 
        }
    }
    &__application-link{
        color: var(--green);
        &:hover{
            color: #2c3d2d;
        }
    }
}

@media only screen and  (max-width: 600px) {
    .preschool-page {
        &__h2{
            &--right{
                margin-left: 0;
            }
            &--left{
                margin-right: 10rem;
            }
        }
        &__image{
            max-height: 11rem;
            &--right{
                //display: none;
            }
        }
    }
}

@media only screen and (max-width: 1600px) {
    .preschool-page {
        &__body {
            padding: 0 19rem 1.5rem 19rem;
        }
    }
}
@media only screen and (max-width: 1200px) {
    .preschool-page {
        &__body {
            padding: 0 12rem 1.5rem 12rem;
        }
    }
}
@media only screen and (max-width: 768px) {
    .preschool-page {
        &__paragraph{
            font-size: 1.25rem;
        }
        &__image{
            padding-right: 1rem;
            max-height: 11rem;
            &--right{
                padding-left: 0.8rem;
            }
        }
        &__body {
            padding: 0 4rem 1.2rem 4rem;
        }
    }
}
@media only screen and (max-width: 550px) {
    .preschool-page {
        &__body {
            padding: 0 2rem 1rem 2rem;
        }
    }
}