.application-page {
    background-color: var(--white);
    height: 100%;
    min-height: 80vh;
    padding: 2rem 5rem;
    &__additional-information {
        margin: auto;
        margin-bottom: 1rem;
        max-width: 30rem;
        text-align: center;
    }
}
@media only screen and (max-width: 720px) {
    .application-page {
        min-height: calc(80vh - 8rem);
        padding: 7rem 1rem 1rem 1rem;
    }
}
