@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Lato:wght@300;400;700&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Paytone+One&family=Source+Serif+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
html,
body,
#root,
.App {
    background-color: var(--white);
    min-height: 100%;
    width: 100%;
}
html {
    background-color: gray;
    /* --dark: #202229;*/
    --white: #fefff5;
    --dark: #252121;
    --green: #4e6c50;
    --dark-green: #395144;
    /* #FFFDFA */
    /* #FFFAF5 */
    /* #252121 - M.M. dark */
    /* #f5f5f5 M.M. white */
}
* {
    /* color: #202229; */
    box-sizing: border-box;
    font-family: "Inter", "Montserrat", Helvetica, "sans-serif";
    margin: 0;
}
.layout {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100vh;
    position: relative;
}

.page {
    flex: 1 0 auto;
    padding-top: 2rem;
    &__title{
        font-family: "Montserrat", "Inter", "sans-serif";
        font-size: 3rem;
        font-weight: 700;
        margin-bottom: 2rem;
        text-align: center;
    }
    &__body{
        padding: 0 15rem 2rem 15rem;
    }
}
.button {
    background-color: var(--dark-green);
    border: none;
    border-radius: 0.2rem;
    cursor: pointer;
    display: block;
    font-size: 1.2rem;
    font-weight: 400;
    margin: auto;
    margin-top: 1rem;
    padding: 0.8rem;
}
.button a {
    color: var(--white);
    text-decoration: none;
}
.button:hover {
    background-color: var(--green);
    cursor: pointer;
}
.submit-button {
    background-color: var(--green);
    border: 2px solid var(--dark-green);
    border-radius: 0.2rem;
    color: var(--white);
    font-family: "Montserrat", "Inter", sans-serif;
    font-size: 0.9rem;
    font-weight: 500;
    grid-column: span 12;
    margin: auto;
    margin-top: 1rem;
    padding: 0.5rem 2rem;
    &:hover{
        background-color: #669268;
        border: 2px solid var(--green);
        cursor: pointer;
    }
    &--light{
        background-color: #e6e7d9;
        color: var(--dark);
        &:hover{
            background-color: var(--white);
            color: var(--dark);
            border: 2px solid var(--dark-green);
            cursor: pointer;
        }
    }
    & a{
        color: var(--white);
        text-decoration: none;
    }
}
// .submit-button:hover {
//     background-color: #669268;
//     border: 2px solid var(--green);
//     cursor: pointer;
// }
// .submit-button a {
//     color: var(--white);
//     text-decoration: none;
// }
/* .wrapper {
    background: #ccc;
    height: 100%;
    margin: 0 auto;
    width: 80%;
}
.h_iframe {
    position: relative;
}
.h_iframe .ratio {
    display: block;
    height: auto;
    width: 100%;
}
.h_iframe iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
} */
@media only screen and (max-width: 1500px) {
    html {
        font-size: 0.9rem;
    }
}
@media only screen and (max-width: 1350px) {
    html {
        font-size: 0.85rem;
    }
}
@media only screen and (max-width: 1200px) {
    html {
        font-size: 0.8rem;
    }
}
@media only screen and (max-width: 950px) {
    html {
        font-size: 0.7rem;
    }
}
@media only screen and (max-width: 720px) {
    .page {
        padding-top: 7rem;
        &__title{
            font-size: 2.5rem;
            margin-bottom: 1.5rem;
        }
    }
}
@media only screen and (max-width: 400px) {
    html {
        font-size: 0.6rem;
    }
}
