.navbar {
    $height: 6rem;
    background-color: #4e6c50;
    display: grid;
    flex-direction: row;
    grid-template-columns: 4rem 3fr auto;
    height: $height;
    padding: 1rem 2rem;
    width: 100%;
    z-index: 2;
    &__logo-container {
        align-items: center;
        aspect-ratio: 1/1;
        display: flex;
        height: calc($height - 2rem);
        justify-content: center;
        margin: 0;
        position: relative;
    }
    &__logo {
        height: inherit;
        width: inherit;
    }
    &__pages {
        align-items: center;
        column-gap: 2rem;
        display: flex;
        justify-content: flex-start;
        padding: 0 4rem;
    }
    &__link {
        color: var(--white);
        font-family: "Montserrat", "Inter", "sans-serif";
        text-decoration: none;
        text-transform: uppercase;
        &--pages {
            font-size: 1.3rem;
            font-weight: 500;
            position: relative;
            &::after {
                background-color: var(--white);
                bottom: -0.2rem;
                //color: var(--white);
                content: "";
                height: 0.1rem;
                left: 50%;
                margin: auto;
                position: absolute;
                transition: width 400ms, left 400ms;
                width: 0%;
            }
            &:hover {
                &::after {
                    left: 0;
                    width: 100%;
                }
            }
        }
        &--logo {
            height: 100%;
            position: absolute;
            width: 100%;
        }
    }
    &__contact-info {
        column-gap: 2rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    &__info {
        align-items: center;
        color: var(--white);
        display: flex;
        a {
            align-items: center;
            color: var(--white);
            display: flex;
            font-family: "Montserrat", "Inter", "sans-serif";
            font-weight: 500;
            text-decoration: none;
        }
    }
    &__icon {
        color: var(--white);
        font-size: 1.3rem;
        margin-right: 0.3rem;
        &--facebook {
            //color: rgb(15, 81, 202);
            color: var(--white);
            cursor: pointer;
            &:hover {
                color: rgb(127, 170, 235);
            }
        }
    }
}
.active {
    //link
    &:after {
        left: 0;
        width: 100%;
    }
}
@media only screen and (max-width: 1200px) {
    .navbar {
        padding: 1rem 1.8rem;
        &__logo {
            height: 3.5rem;
            width: 3.5rem;
        }
        &__pages {
            column-gap: 1.4rem;
            padding: 0rem 3rem;
        }
        &__contact-info {
            column-gap: 1rem;
        }
    }
}
@media only screen and (max-width: 1000px) {
    .navbar {
        $height: 6rem;
        background-color: #4e6c50;
        display: grid;
        flex-direction: row;
        grid-template-columns: 4rem 3fr auto;
        height: $height;
        &__pages {
            column-gap: 1rem;
            padding: 0 2rem;
        }
        &__contact-info {
            column-gap: 1rem;
        }
        &__icon {
            width: 1.6rem;
        }
        &__info a {
            font-weight: 500;
        }
        &__link {
            &--pages {
                font-size: 1.2rem;
            }
        }
    }
}
@media only screen and (max-width: 768px) {
    .navbar {
        $height: 5rem;
        height: $height;
        padding: 1rem 1.5rem;
        &__logo-container {
            height: 3rem;
        }
        &__pages {
            padding: 0 1rem;
        }
        &__info a {
            font-size: 0.9rem;
        }
        &__icon {
            width: 1.4rem;
        }
    }
}
@media only screen and (max-width: 720px) {
    .navbar {
        padding: 1rem 2rem;
        position: fixed;
        &__pages,
        &__contact-info {
            display: none;
        }
    }
}
