.gallery-page {
    padding: 2rem 5rem;

    &__overlay {
        align-items: center;
        background-color: rgba(15, 15, 15, 0.9);
        display: flex;
        height: 100vh;
        inset: 0 0 0 0;
        justify-content: center;
        overflow-y: hidden;
        position: fixed;
        width: 100%;
        z-index: 2;
    }
    &__overlay-image-container {
        $width: 90rem;
        align-items: center;
        background-color: black;
        display: flex;
        height: calc($width * 0.5625);
        justify-content: center;
        margin: auto;
        position: relative;
        width: $width;
    }
    &__close-overlay-button {
        align-items: center;
        aspect-ratio: 1/1;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        font-size: 1.4rem;
        font-weight: 600;
        height: 2rem;
        justify-content: center;
        line-height: 1rem;
        opacity: 0.6;
        position: absolute;
        right: 1rem;
        top: 1rem;
        z-index: 1;
        &:hover {
            opacity: 1;
        }
    }
    &__change-image-panel {
        align-items: center;
        background-color: #17181d15;
        bottom: 0;
        cursor: pointer;
        display: flex;
        justify-content: center;
        opacity: 0.6;
        position: absolute;
        -webkit-tap-highlight-color: transparent;
        top: 0;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        width: 6rem;
        &--right {
            right: 0;
        }
        &--left {
            left: 0;
        }
        &:hover {
            opacity: 1;
        }
        &:active {
            background-color: #17181d15;
            -webkit-tap-highlight-color: transparent;
        }
        &::selection {
            background-color: none;
            color: none;
            -webkit-tap-highlight-color: transparent;
        }
        /* For Mozilla Firefox */
        &::-moz-selection {
            background-color: none;
            color: none;
            -webkit-tap-highlight-color: transparent;
        }
    }
    &__change-image-button {
        align-items: center;
        aspect-ratio: 1/1;
        border-radius: 50%;
        display: flex;
        justify-content: center;
    }
    &__album-container {
        column-gap: 2rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 0 5rem 2rem 5rem;
    }
    &__album {
        box-shadow: 0px 4px 8px gray;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        height: 10rem;
        transition: border 10ms ease, transform 200ms ease;
        width: 12rem;
        &:hover {
            box-shadow: 0px 5px 10px gray;
            transform: translate3D(0, -1px, 0) scale(1.06);
        }
        &--selected {
            border: 0.2rem solid var(--dark-green);
        }
    }
    &__album-image {
        height: 60%;
        width: 100%;
    }
    &__album-info {
        align-items: center;
        background-color: var(--white);
        color: var(--dark);
        display: flex;
        flex-direction: column;
        font-size: 1rem;
        height: 40%;
        justify-content: space-evenly;
        padding: 0.4rem;
        width: 100%;
    }
    &__album-year {
        font-family: "Montserrat", "Inter", "sans-serif";
        font-size: 1rem;
    }
    &__album-title {
        color: var(--dark-green);
        font-family: "Montserrat", "Inter", "sans-serif";
        font-size: 0.8rem;
        text-align: center;
    }
    &__video-container {
        display: flex;
        height: 22.5rem;
        justify-content: center;
        margin: auto;
        margin-bottom: 2rem;
        padding-bottom: 2rem;
        position: relative;
        width: 40rem;
    }
    &__image-skeleton {
        background-color: rgb(183, 183, 183);
        break-inside: avoid-column;
        cursor: pointer;
        display: block;
        height: auto;
        margin-bottom: 0.5rem;
        width: 100%;
    }
}

.gallery-grid {
    columns: 5;
    gap: 0.5rem;
    height: min-content;
}
.gallery-image {
    cursor: pointer;
    display: block;
    height: auto;
    margin-bottom: 0.5rem;
    object-fit: cover;
    width: 100%;
    &--current {
        $width: 90rem;
        cursor: default;
        display: block;
        height: auto;
        margin: 0;
        max-height: calc($width * 0.5625);
        max-width: $width;
        width: auto;
    }
}

.video-responsive {
    font-size: 0.5rem;
    margin: auto;
    padding-bottom: 2rem;
    width: fit-content;
}

@media only screen and (max-width: 1400px) {
    .gallery-page {
        &__overlay-image-container {
            $width: 80rem;
            height: calc($width * 0.5625);
            width: $width;
        }
    }
    .gallery-image {
        &--current {
            $width: 80rem;
            max-height: calc($width * 0.5625);
            max-width: $width;
        }
    }
}
@media only screen and (max-width: 1200px) {
    .gallery-page {
        padding: 2rem 4rem;
        &__overlay-image-container {
            $width: 75rem;
            height: calc($width * 0.5625);
            width: $width;
        }
        &__album-container {
            column-gap: 1rem;
        }
        &__album {
            height: 10rem;
            width: 11.5rem;
        }
    }
    .gallery-image {
        &--current {
            $width: 70rem;
            max-height: calc($width * 0.5625);
            max-width: $width;
        }
    }
}
@media only screen and (max-width: 1050px) {
    .gallery-page {
        &__overlay-image-container {
            $width: 60rem;
            height: calc($width * 0.5625);
            width: $width;
        }
    }
    .gallery-image {
        &--current {
            $width: 60rem;
            max-height: calc($width * 0.5625);
            max-width: $width;
        }
    }
}
@media only screen and (max-width: 900px) {
    .gallery-page {
        padding: 2rem 3rem;
        &__album-container {
            column-gap: 1rem;
            flex-wrap: wrap;
            row-gap: 1rem;
        }
        &__album {
            height: 9rem;
            width: 10rem;
        }
        &__video-container {
            height: 17.775rem;
            width: 31.6rem;
        }
        &__overlay-image-container {
            $width: 55rem;
            height: calc($width * 0.5625);
            width: $width;
        }
    }
    .gallery-image {
        &--current {
            $width: 55rem;
            max-height: calc($width * 0.5625);
            max-width: $width;
        }
    }
}
@media only screen and (max-width: 720px) {
    .gallery-page {
        padding: 8rem 1.5rem 2rem 1.5rem;
        &__overlay-image-container {
            $width: 35rem;
            height: calc($width * 0.5625);
            width: $width;
        }
        &__album-container {
            column-gap: 1rem;
            flex-wrap: wrap;
            padding: 0 3rem 2rem 3rem;
            row-gap: 1rem;
        }
        &__album {
            height: 8rem;
            width: 8rem;
        }
        &__album-image {
            height: 55%;
        }
        &__album-info {
            height: 45%;
        }
        &__video-container {
            height: 14.06rem;
            width: 25rem;
        }
        &__image-skeleton {
            margin-bottom: 0.4rem;
        }
    }
    .gallery-grid {
        columns: 4;
        gap: 0.4rem;
    }
    .gallery-image {
        margin-bottom: 3px;
        &--current {
            $width: 35rem;
            max-height: calc($width * 0.5625);
            max-width: $width;
        }
    }
}
@media only screen and (max-width: 500px) {
    .gallery-page {
        &__overlay-image-container {
            width: 34rem;
        }
    }
    .gallery-image {
        &--current {
            max-width: 34rem;
        }
    }
}

@media only screen and (max-width: 400px) {
    .gallery-page {
        &__overlay-image-container {
            width: 35rem;
        }
        &__image-skeleton {
            margin-bottom: 0.3rem;
        }
    }
    .gallery-grid {
        columns: 3;
        gap: 0.3rem;
    }
    .gallery-image {
        margin-bottom: 2px;
        &--current {
            max-width: 35rem;
        }
    }
}
.responsive-iframe {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}
