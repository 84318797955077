.home-page {
    padding-top: 0;
}
.wave {
    bottom: -2px;
    left: 0;
    position: absolute;
    path {
        fill: var(--white);
        height: 50%;
    }
}
.grid-split-container {
    column-gap: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.section-title {
    font-family: "Montserrat", "Inter", sans-serif;
    font-size: 2.6rem;
    margin-bottom: 1.5rem;
    text-align: center;
}

.main-section {
    background-color: black;
    height: 35rem;
    overflow: hidden;
    padding: 3rem;
    position: relative;
    &__title-container {
        background: linear-gradient(
            90deg,
            rgba(0, 0, 0, 1) 0%,
            rgba(0, 0, 0, 1) 30%,
            rgba(0, 0, 0, 0.75) 45%,
            rgba(0, 0, 0, 0.3) 60%,
            rgba(0, 0, 0, 0) 100%
        );
        inset: 0px 0px 0px 0px;
        padding: 4rem;
        position: absolute;
        width: 100%;
    }
    &__title,
    &__title-description,
    &__date,
    &__spots-left,
    &__application-button {
        color: var(--white);
        font-family: "Montserrat", "Inter", sans-serif;
        font-size: 3rem;
        font-weight: 400;
    }
    &__title {
        font-size: 6rem;
        font-weight: 600;
        line-height: 1;
        margin-bottom: 0.8rem;
    }
    &__date-container {
        column-gap: 2rem;
        display: flex;
        margin-top: 4rem;
    }
    &__spots-left {
        font-size: 2rem;
        margin-top: 0.6rem;
    }
    &__spots-left-number {
        //font-size: 2.4em;
        font-weight: 600;
    }
    &__application-button {
        background-color: unset;
        border: 1px solid var(--white);
        cursor: pointer;
        font-size: 1.6rem;
        padding: 0.8rem 1rem;
        transition: background-color 200ms;
        a {
            color: var(--white);
            position: relative;
            text-decoration: none;
        }
        &:hover {
            background-color: var(--green);
        }
    }
}
.home-page-about-us-section {
    background-color: var(--white);
    column-gap: 0.4rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    //grid-template-rows: 23rem 23rem;
    padding: 3rem 8rem;
    //padding-bottom: 3rem;
    row-gap: 0.3rem;
}
.home-page-about-us {
    padding: 1rem 2rem;
    &__second-container {
        padding: 1rem 2rem;
    }
    &__list {
        font-size: 1.35rem;
        font-weight: 500;
        padding-left: 2rem;
    }
    &__title {
        color: var(--dark-green);
        font-weight: 500;
    }
    &__text {
        font-size: 1.4rem;
        font-weight: 500;
        text-align: justify;
    }
    &__picture-container {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        overflow: hidden;
        padding: 2rem;
        position: relative;
        width: 100%;
    }
    &__button {
        grid-column: span 2;
    }
}
.image {
    max-width: 100%;
    &--streched {
        aspect-ratio: 10/6.15;
        width: 35rem;
        // height: inherit;
        // width: inherit;
    }
    &--main {
        height: 38rem;
        inset: 0 0 0 0;
        left: unset;
        max-height: unset;
        position: absolute;
        top: -3rem;
        width: 82rem;
    }
}
.program-section {
    background-color: var(--green);
    padding: 2rem 0;
}
.program {
    column-gap: 2rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin: auto;
    width: 72rem;
    &__title {
        color: var(--white);
        font-weight: 500;
    }
    &__item {
        display: grid;
        grid-template-rows: 3fr 2.5fr 5fr;
        row-gap: 1rem;
        //height: 45vh;
        text-align: center;
    }
    &__icon {
        color: gray;
        height: auto;
        margin: auto;
        width: 4rem;
        &--guitar {
            width: 5.5rem;
        }
    }
    &__mini-title {
        color: var(--white);
        font-size: 1.6rem;
        font-weight: 500;
    }
    &__description {
        color: var(--white);
    }
}

.gallery-section {
    background-color: var(--white);
    margin: auto;
    padding: 2rem;
    width: min-content;
}
.gallery {
    $multiplier: 1.8;
    $column: 16rem;
    $row: 9rem;
    --width: calc(100%);
    column-gap: 0.6rem;
    display: grid;
    grid-template-columns: repeat(3, calc($column * $multiplier));
    grid-template-rows: repeat(2, calc($row * $multiplier));
    height: fit-content;
    margin: auto;
    max-height: 40rem;
    row-gap: 0.6rem;
    width: var(--width);
    &__title {
        font-weight: 500;
    }
    &__image-container {
        aspect-ratio: 16/9;
        overflow: hidden;
        width: var(--width);
    }
    &__image {
        aspect-ratio: 16/9;
        object-fit: cover;
        width: 100%;
    }
}
@media only screen and (max-width: 1700px) {
    .main-section {
        height: 33rem;
        &__title-container {
            padding: 3rem;
        }
        &__title,
        &__title-description,
        &__date,
        &__spots-left,
        &__application-button {
            font-size: 2.4rem;
        }
        &__title {
            font-size: 5.5rem;
            font-weight: 600;
            line-height: 1;
            margin-bottom: 0.8rem;
        }
        &__date-container {
            column-gap: 2rem;
            display: flex;
            margin-top: 6rem;
        }
        &__spots-left {
            font-size: 1.6rem;
        }
        &__application-button {
            font-size: 1.3rem;
        }
    }
    .image--main {
        right: -5rem;
        top: -5rem;
    }
    .home-page-about-us-section {
        padding: 4rem 6rem;
        padding-bottom: 3rem;
    }
}

@media only screen and (max-width: 1500px) {
    .main-section {
        height: 30rem;
        &__title-container {
            padding: 3rem;
        }
        &__title,
        &__title-description,
        &__date,
        &__spots-left,
        &__application-button {
            font-size: 2.2rem;
        }
        &__title {
            font-size: 5rem;
            font-weight: 500;
            line-height: 1;
            margin-bottom: 0.8rem;
        }
        &__date-container {
            column-gap: 2rem;
            display: flex;
            margin-top: 5rem;
        }
        &__spots-left {
            font-size: 1.4rem;
        }
        &__application-button {
            font-size: 1.2rem;
        }
    }
    .image--main {
        right: -3rem;
        top: -8rem;
    }
    .home-page-about-us-section {
        padding: 2rem 5rem;
    }
    .gallery-section {
        padding: 1.5rem;
    }
    .gallery {
        $multiplier: 1.6;
        $column: 16rem;
        $row: 9rem;
        grid-template-columns: repeat(3, calc($column * $multiplier));
        grid-template-rows: repeat(2, calc($row * $multiplier));
    }
}
@media only screen and (max-width: 1400px) {
    .home-page-about-us-section {
        padding: 2rem 3rem;
    }
}
@media only screen and (max-width: 1200px) {
    .main-section {
        height: 30rem;
        &__title-container {
            padding: 3rem;
        }
        &__title,
        &__title-description,
        &__date,
        &__spots-left,
        &__application-button {
            font-size: 2.2rem;
        }
        &__title {
            font-size: 5rem;
            font-weight: 500;
            line-height: 1;
            margin-bottom: 0.8rem;
        }
        &__date-container {
            column-gap: 2rem;
            display: flex;
            margin-top: 5rem;
        }
        &__spots-left {
            font-size: 1.4rem;
        }
        &__application-button {
            font-size: 1.2rem;
        }
    }
    .image--main {
        right: -10rem;
        top: -8rem;
    }
    .home-page-about-us-section {
        padding: 2rem 2rem;
    }
    .home-page-about-us {
        padding: 1rem;
        &__second-container {
            padding: 1rem;
        }
        &__list {
            font-size: 1.2rem;
        }
        &__text {
            font-size: 1.2rem;
        }
    }
    .gallery-section {
        padding: 1.1rem;
    }
    .gallery {
        $multiplier: 1.4;
        $column: 16rem;
        $row: 9rem;
        grid-template-columns: repeat(3, calc($column * $multiplier));
        grid-template-rows: repeat(2, calc($row * $multiplier));
        row-gap: 0.6rem;
    }
}
@media only screen and (max-width: 1050px) {
    .main-section {
        height: 27rem;
        &__title-container {
            padding: 3rem 2rem;
        }
        &__title,
        &__title-description,
        &__date,
        &__spots-left,
        &__application-button {
            font-size: 2rem;
        }
        &__title {
            font-size: 4.5rem;
            font-weight: 500;
            line-height: 1;
            margin-bottom: 0.8rem;
        }
        &__date-container {
            column-gap: 2rem;
            margin-top: 4rem;
        }
        &__spots-left {
            font-size: 1.2rem;
        }
        &__application-button {
            font-size: 1rem;
        }
    }
    .image--main {
        right: -10rem;
        top: -11rem;
    }
    .home-page-about-us-section {
        padding: 2rem;
    }
    .section-title {
        font-size: 2.2rem;
        margin-bottom: 0.5rem;
    }
    .program {
        width: 65rem;
    }
}
@media only screen and (max-width: 950px) {
    .gallery-section {
        padding: 1.1rem;
    }
    .gallery {
        $multiplier: 1.4;
        $column: 16rem;
        $row: 9rem;
        grid-template-columns: repeat(3, calc($column * $multiplier));
        grid-template-rows: repeat(2, calc($row * $multiplier));
        row-gap: 0.6rem;
    }
}
@media only screen and (max-width: 820px) {
    .main-section {
        height: 25rem;
        &__title-container {
            padding: 2rem;
        }
        &__title,
        &__title-description,
        &__date,
        &__spots-left,
        &__application-button {
            font-size: 1.8rem;
        }
        &__title {
            font-size: 4rem;
            font-weight: 500;
            line-height: 1;
            margin-bottom: 0.8rem;
        }
        &__date-container {
            column-gap: 2rem;
            margin-top: 4rem;
        }
        &__spots-left {
            font-size: 1.2rem;
        }
        &__application-button {
            font-size: 0.8rem;
            padding: 0.7rem 0.9rem;
        }
    }
    .image--main {
        right: -10rem;
        top: -13rem;
    }
    .home-page-about-us-section {
        padding: 2rem;
    }
    .section-title {
        font-size: 2.2rem;
    }
    .program {
        column-gap: 1rem;
        width: 60rem;
        &__item {
            grid-template-rows: 3fr 2fr 5fr;
        }
        &__mini-title {
            font-size: 1.5rem;
        }
    }
    .gallery {
        $multiplier: 1.25;
        $column: 16rem;
        $row: 9rem;
        column-gap: 0.4rem;
        grid-template-columns: repeat(3, calc($column * $multiplier));
        grid-template-rows: repeat(2, calc($row * $multiplier));
        row-gap: 0.4rem;
    }
}
@media only screen and (max-width: 720px) {
    .home-page {
        padding-top: 5rem;
    }
    .main-section {
        height: 22rem;
        &__title-container {
            padding: 2rem;
        }
        &__title,
        &__title-description,
        &__date,
        &__spots-left,
        &__application-button {
            font-size: 1.6rem;
        }
        &__title {
            font-size: 3.5rem;
            font-weight: 500;
            line-height: 1;
            margin-bottom: 0.5rem;
        }
        &__date-container {
            column-gap: 2rem;
            margin-top: 3.5rem;
        }
        &__spots-left {
            font-size: 1rem;
        }
        &__application-button {
            font-size: 0.8rem;
            padding: 0.6rem 0.8rem;
        }
    }
    .image--main {
        max-width: 110%;
        right: -12rem;
        top: -16rem;
    }
    .home-page-about-us-section {
        column-gap: 0rem;
        grid-template-rows: 1fr;
        padding: 2rem;
    }
    .home-page-about-us {
        &__list {
            font-size: 1.25rem;
        }
        &__text {
            font-size: 1.25rem;
        }
    }
    .picture {
        &--about-us {
            display: none;
        }
    }
    .program {
        column-gap: 1rem;
        width: 55rem;
    }
    .section-title {
        font-size: 2rem;
    }
    .gallery-section {
        padding: 1rem;
    }
    .gallery {
        $multiplier: 1.15;
        $column: 16rem;
        $row: 9rem;
        column-gap: 0.3rem;
        grid-template-columns: repeat(3, calc($column * $multiplier));
        grid-template-rows: repeat(2, calc($row * $multiplier));
        row-gap: 0.3rem;
    }
}
@media only screen and (max-width: 650px) {
    .main-section {
        height: 19rem;
        &__title-container {
            padding: 1.5rem;
        }
        &__title,
        &__title-description,
        &__date,
        &__spots-left,
        &__application-button {
            font-size: 1.4rem;
        }
        &__title {
            font-size: 3rem;
            font-weight: 500;
            line-height: 1;
            margin-bottom: 0.5rem;
        }
        &__date-container {
            column-gap: 1.5rem;
            margin-top: 3.5rem;
        }
        &__spots-left {
            font-size: 1rem;
        }
        &__application-button {
            font-size: 0.8rem;
            padding: 0.5rem 0.6rem;
        }
    }
    .image--main {
        max-width: 120%;
        top: -19rem;
    }
    .home-page-about-us-section {
        grid-template-columns: 1fr;
        grid-template-rows: 4fr 4fr;
        padding: 2rem 4rem;
    }
    .home-page-about-us {
        &__button {
            grid-column: span 1;
        }
    }
    .program {
        column-gap: 2rem;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2m, 1fr);
        row-gap: 1rem;
        width: 35rem;
        &__title {
            margin-bottom: 1.5rem;
        }
        &__item {
            grid-template-rows: 3fr 2fr 4fr;
        }
    }
    .gallery {
        $multiplier: 1.35;
        $column: 16rem;
        $row: 9rem;
        column-gap: 0.4rem;
        grid-template-columns: repeat(2, calc($column * $multiplier));
        grid-template-rows: repeat(3, calc($row * $multiplier));
        row-gap: 0.4rem;
    }
}
@media only screen and (max-width: 550px) {
    .home-page-about-us-section {
        padding: 2rem 2.5rem;
    }
    .gallery {
        $multiplier: 1.2;
        $column: 16rem;
        $row: 9rem;
        column-gap: 0.4rem;
        grid-template-columns: repeat(2, calc($column * $multiplier));
        grid-template-rows: repeat(3, calc($row * $multiplier));
        row-gap: 0.4rem;
    }
}
@media only screen and (max-width: 480px) {
    .home-page-about-us-section {
        padding: 2rem;
    }
    .gallery {
        $multiplier: 1;
        $column: 16rem;
        $row: 9rem;
        column-gap: 0.4rem;
        grid-template-columns: repeat(2, calc($column * $multiplier));
        grid-template-rows: repeat(3, calc($row * $multiplier));
        row-gap: 0.4rem;
    }
}
@media only screen and (max-width: 400px) {
    .image--main {
        max-width: 140%;
    }
    .program {
        width: 30rem;
    }
    .gallery-section {
        padding: 1rem;
    }
    .gallery {
        $multiplier: 1.05;
        $column: 16rem;
        $row: 9rem;
        column-gap: 0.4rem;
        grid-template-columns: repeat(2, calc($column * $multiplier));
        grid-template-rows: repeat(3, calc($row * $multiplier));
        row-gap: 0.4rem;
    }
}
@media only screen and (max-width: 350px) {
    .gallery {
        $multiplier: 0.95;
        $column: 16rem;
        $row: 9rem;
        grid-template-columns: repeat(2, calc($column * $multiplier));
        grid-template-rows: repeat(3, calc($row * $multiplier));
    }
}
