.form {
    column-gap: 1rem;
    display: grid;
    grid-template-columns: repeat(12, 2.1rem);
    grid-template-rows: 3rem repeat(4, 1fr);
    height: fit-content;
    margin: auto;
    padding: 1rem 0;
    width: fit-content;
    &__laoder{
        position: absolute;
        inset: 0 0 0 0;
    }
    //min-width: 46rem;
    &__label {
        //color: rgb(73, 73, 73);
        color: #4d4e53;
        display: block;
        margin-bottom: 0.1rem;
    }
    &__input-container {
        grid-column: span 6;
        margin-bottom: 0.4rem;
        &--mini{
            grid-column: span 2;
        }
        &--small {
            grid-column: span 4;
        }
        &--semi-small{
            grid-column: span 5;
        }
        &--medium {
            grid-column: span 6;
        }
        &--semi-large{
            grid-column: span 7;
        }
        &--large {
            grid-column: span 8;
        }
        &--full {
            grid-column: span 12;
        }
    }
    &__input {
        background-color: #f3f4f4;
        border: 1px solid rgb(202, 202, 202);
        border-radius: 0.2rem;
        outline: none;
        //margin-bottom: 1rem;
        padding: 0.7rem 1rem;
        width: 100%;
    }
    &__question-textarea{
        height: 12rem;
        resize: vertical;
        min-height: 12rem;
        max-height: 16rem;
    }
    &__required-char {
        color: rgb(255, 107, 107);
    }
    &__error {
        color: rgb(255, 107, 107);
        grid-column: span 8;
        height: 2rem;
        font-size: 1.1rem;
        text-align: center;
    }
    &__form-submitted{
        &__container{
            max-width: 90%;
            justify-content: center;
            margin: auto;
            text-align: center;
            font-size: 1.5rem;
            font-weight: 400;
            line-height: 1.3;
        }
        &__message{
            font-weight: 400;
        }
        &__button{
            margin-top: 2.4rem;
        }
    }
}
label {
    font-size: 0.85rem;
    font-weight: 600;
    margin-bottom: 0.1rem;
}
@media only screen and (max-width: 560px){
    .form{
        grid-template-columns: repeat(12, 2.5rem);
        // &__input-container{
        //     &--small-to-medium{
        //         grid-column: span 6;
        //     }
        //     &--small-to-full{
        //         grid-column: span 12;
        //     }
        // }
    }
}
@media only screen and (max-width: 540px){
    .form{
        grid-template-columns: repeat(12, 2.3rem);
    }
}
@media only screen and (max-width: 460px){
    .form{
        grid-template-columns: repeat(12, 2.1rem);
    }
}
@media only screen and (max-width: 420px){
    .form{
        grid-template-columns: repeat(12, 1.9rem);
    }
}