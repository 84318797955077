.footer-container {
    padding: 0 10rem 0 10rem;
}
.footer {
    align-items: center;
    background-color: #395144;
    color: var(--white);
    display: flex;
    flex-direction: column;
    height: min-content;
    //margin-top: 1rem;
    &__info {
        column-gap: 8rem;
        display: flex;
        height: 100%;
        justify-content: row;
        padding: 3rem 1.5rem 2rem 1.5rem;
    }
    &__logo-container {
        align-items: center;
        display: flex;
        justify-content: center;
    }
    &__logo {
        width: 5.5rem;
    }
    &__map {
        height: 22rem;
    }
}
.footer-contact-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &__title {
        font-family: "Montserrat", "Inter", "sans-serif";
        font-size: 1.6rem;
        font-weight: 500;
    }
    &__list {
        list-style-type: none;
        padding: 0;
    }
    &__address-and-icon-container {
        align-items: center;
        display: flex;
        //height: 1rem;
    }
    &__item {
        align-items: center;
        display: flex;
        padding: 0.2rem 0;
        // &--address {
        //     flex-direction: column;
        // }
        a {
            align-items: center;
            color: var(--white);
            display: flex;
            text-decoration: none;
        }
    }
    &__icon {
        color: var(--white);
        margin-right: 0.5rem;
    }
}
.footer-navigation {
    &__title {
        font-family: "Montserrat", "Inter", "sans-serif";
        font-size: 1.6rem;
        font-weight: 500;
        margin-bottom: 0.5rem;
    }
    &__list {
        list-style-type: none;
        padding: 0;
    }
    &__item {
        padding: 0.2rem 0;
        //text-align: center;
    }
    &__link {
        color: var(--white);
        font-family: "Montserrat", "Inter", "sans-serif";
        font-size: 1.2rem;
        position: relative;
        text-decoration: none;
        text-transform: uppercase;
        z-index: 0;
        &::after {
            background-color: var(--white);
            bottom: -0.2rem;
            //color: var(--white);
            content: "";
            height: 2px;
            left: 50%;
            margin: auto;
            position: absolute;
            transition: width 400ms, left 400ms;
            width: 0%;
        }
        &:hover {
            &::after {
                left: 0;
                width: 100%;
            }
        }
    }
}
.copyright-and-author {
    align-items: center;
    border-top: 2px solid var(--white);
    display: flex;
    height: 5rem;
    justify-content: center;
    text-align: center;
    width: 100%;
}
@media only screen and (max-width: 1800px) {
    .footer-container {
        padding: 0 6rem;
    }
    .footer {
        &__info {
            column-gap: 5rem;
        }
        &__map {
            height: 16rem;
        }
    }
}
@media only screen and (max-width: 1700px) {
    .footer__info {
        column-gap: 5rem;
    }
}
@media only screen and (max-width: 1050px) {
    .footer-container {
        padding: 0 8rem;
    }
    .footer {
        &__logo {
            width: 4rem;
        }
        &__info {
            column-gap: 4rem;
        }
        &__map {
            height: 18rem;
        }
    }
}
@media only screen and (max-width: 850px) {
    .footer-container {
        padding: 0 4rem;
    }
    .footer {
        font-size: 0.8rem;
        //height: 18rem;
        &__info {
            column-gap: 3rem;
        }
    }
    .footer-contact-info__item {
        padding: 0;
        b {
            //width: min-content;
            white-space: nowrap;
        }
    }
    .footer-contact-info__icon {
        margin-right: 0.3rem;
        width: 1.8rem;
    }
}
@media only screen and (max-width: 650px) {
    .footer-container {
        padding: 2rem 4rem 0 4rem;
    }
    .footer {
        font-size: 0.9rem;
        &__info {
            align-self: flex-start;
            column-gap: 1.8rem;
            flex-direction: column;
            margin: auto;
            padding: 0rem;
            row-gap: 1rem;
        }
        &__logo {
            width: 3rem;
        }
    }
    .footer-contact-info {
        align-items: center;
        display: flex;
        flex-direction: column;
        padding-bottom: 1rem;
        text-align: center;
        &__note {
            display: none;
        }
        &__icon {
            // &--map {
            //     height: min-content;
            //     margin-bottom: auto;
            //     width: 2rem;
            // }
        }
    }
    .footer-navigation {
        text-align: center;
    }
    .footer-contact-info__icon {
        margin-right: 0.2rem;
        width: 1.5rem;
    }
    .copyright-and-author {
        height: 4rem;
    }
}
@media only screen and (max-width: 370px) {
    .footer {
        &__info {
            padding: 0 3rem;
        }
    }
    .footer-contact-info {
        // &__address-text {
        //     padding: 0 2rem;
        // }
        &__icon {
            height: min-content;
            width: 1.4rem;
        }
        &__item {
            flex-direction: column;
            a {
                flex-direction: column;
                margin-bottom: 0.5rem;
            }
            &--address {
                margin-bottom: 0.5rem;
                padding: 0 0rem;

                //text-align: left;
            }
        }
    }
}
