.navbar {
    &__menu {
        align-items: center;
        display: flex;
        grid-column-start: 3;
        justify-content: center;
        position: relative;
        -webkit-user-select: none;
        user-select: none;
        z-index: 1;
    }
    &__hamburger {
        display: flex;
        flex-direction: column;
        height: 1.7rem;
        justify-content: space-between;
        position: relative;
        width: 2rem;
        span {
            align-items: center;
            background: var(--white);
            border-radius: 3px;
            display: flex;
            height: 3px;
            justify-content: center;
            position: absolute;
            top: calc(50% - 2px);
            //transform-origin: 4px 0px;
            transform-origin: center center;
            transition: transform 0.3s cubic-bezier(0.77, 0.2, 0.05, 1),
                opacity 0.2s;
            width: 2rem;
            z-index: 1;
            &:first-child {
                transform: translateY(-0.6rem);
            }
            &:nth-child(3) {
                transform: translateY(0.6rem);
            }
        }
        &--open {
            span {
                opacity: 1;
                position: absolute;
                top: calc(50% - 2px);
                transform-origin: center center;
                &:first-child {
                    transform: rotate(45deg);
                }
                &:nth-child(2) {
                    opacity: 0;
                    transform: unset;
                }
                &:last-child {
                    transform: rotate(-45deg);
                }
            }
            & ~ .navbar__menu-container {
                border-top: 2px solid var(--white);
                height: 100%;
                transition: height 200ms, border 50ms;
            }
        }
    }
    &__menu-container {
        border: none;
        height: 0px;
        list-style-type: none;
        overflow: hidden;
        position: fixed;
        right: 0;
        top: 5rem;
        transition: height 200ms, border 50ms;
        width: 100%;
    }
    &__menu-navigation {
        //height: 15rem;
        align-items: center;
        background-color: #4e6c50;
        display: flex;
        flex-direction: column;
        padding: 3rem 3rem 1rem 3rem;
        row-gap: 1rem;
    }
    // &__menu-line-container {
    //     background-color: #4e6c50;
    // }
    // &__menu-line {
    //     margin: auto;
    //     width: 30rem;
    // }
    &__menu-contact-info {
        align-items: center;
        background-color: #4e6c50;
        display: flex;
        flex-direction: column;
        //height: 12rem;
        padding: 3rem 3rem 2rem 3rem;
        row-gap: 0.5rem;
    }

    &__menu-contact-info-title {
        color: var(--white);
        font-family: "Montserrat", "Inter", "sans-serif";
        font-size: 1.8rem;
    }
    &__menu-info {
        align-items: center;
        color: var(--white);
        display: flex;
        font-size: 1.3rem;
        padding-left: 0.8rem;
        a {
            align-items: center;
            color: var(--white);
            display: flex;
            font-family: "Montserrat", "Inter", "sans-serif";
            font-weight: 500;
            text-decoration: none;
        }
    }
    &__menu-icon {
        color: var(--white);
        height: 2rem;
        margin-right: 0.5rem;
        &--facebook {
            //color: rgb(15, 81, 202);
            color: var(--white);
            cursor: pointer;
            &:hover {
                color: rgb(127, 170, 235);
            }
        }
    }

    &__menu-link {
        color: var(--white);
        font-family: "Montserrat", "Inter", "sans-serif";
        font-size: 2.2rem;
        font-weight: 500;
        //padding: 0.5rem 2rem;
        position: relative;
        text-decoration: none;
        text-transform: uppercase;
        // &::after {
        //     background-color: var(--white);
        //     bottom: -0.2rem;
        //     content: "";
        //     height: 0.1rem;
        //     left: 50%;
        //     margin: auto;
        //     position: absolute;
        //     transition: width 400ms, left 400ms;
        //     width: 0%;
        // }
        // &:hover {
        //     &::after {
        //         left: 0;
        //         width: 100%;
        //     }
        // }
        // &:nth-of-type(1) {
        //     margin-top: 2rem;
        // }
    }
}
@media only screen and (min-width: 721px) {
    .navbar__menu {
        display: none;
    }
}
