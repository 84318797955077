.for-parents-page {
    color: var(--dark);
    &__body {
        padding: 0 15rem 2rem 15rem;
        font-size: 1.1rem;
    }
    &__section {
        padding-bottom: 1rem;
    }
    &__section-title {
        font-weight: 600;
        margin-bottom: 0.2rem;
        text-transform: uppercase;
    }
    &__basic-info {
        font-weight: 500;
    }
    &__question-section {
        align-items: center;
        background-color: var(--green);
        display: flex;
        flex-direction: column;
        height: fit-content;
        justify-content: center;
        padding: 1rem;
        row-gap: 1.5rem;
        width: 100%;
        min-height: 27rem;
    }
    &__question-title {
        color: var(--white);
        font-family: "Montserrat", "Inter", "sans-serif";
        font-size: 2.2rem;
        font-weight: 600;
    }
    &__question-form {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        row-gap: 0.8rem;
    }
    &__question-label {
        color: var(--white);
        font-size: 1.2rem;
        font-weight: normal;
        margin-right: 0.5rem;
    }
    &__question-email {
        background-color: #f3f4f4;
        //border: 1px solid rgb(202, 202, 202);
        border: 2px solid var(--dark-green);
        border-radius: 0.2rem;
        outline: none;
        //margin-bottom: 1rem;
        padding: 0.7rem 1rem;
        width: 18rem;
    }
    &__question-textarea {
        background-color: #f3f4f4;
        //border: 1px solid rgb(202, 202, 202);
        border: 2px solid var(--dark-green);
        border-radius: 0.2rem;
        height: 12rem;
        outline: none;
        padding: 0.7rem 1rem;
        resize: none;
        width: 40rem;
    }
    &__question-form-error {
        color: rgb(255, 107, 107);
        font-size: 1.1rem;
        text-align: center;
    }
}

.highlighted-text {
    background-color: #20222933;
    border-radius: 0.1rem;
    padding: 0 0.2rem;
    // &:after {
    //     background-color: var(--dark);
    //     border-radius: 0.1rem;
    //     content: "";
    //     inset: 0px -1px;
    //     opacity: 0.2;
    //     position: absolute;
    // }
    &--green {
        background-color: var(--green);
        // &:after {
        //     background-color: var(--green);
        // }
    }
}
// ::selection {
//     background: #ffb7b7; /* WebKit/Blink Browsers */
// }
// ::-moz-selection {
//     background: #ffb7b7; /* Gecko Browsers */
// }

@media only screen and (max-width: 1200px) {
    .for-parents-page {
        &__body {
            padding: 0 10rem 1.5rem 10rem;
            font-size: 1.3rem;
        }
    }
}
@media only screen and (max-width: 768px) {
    .for-parents-page {
        &__body {
            padding: 0 4rem 1.2rem 4rem;
            font-size: 1.25rem;
        }
        &__section ul {
            padding-left: 3rem;
        }
    }
}
@media only screen and (max-width: 550px) {
    .for-parents-page {
        &__body {
            padding: 0 2rem 1rem 2rem;
        }
        &__section ul {
            padding-left: 2.5rem;
        }
        &__question-textarea {
            width: 34rem;
        }
    }
}

// @media only screen and (max-width: 400px) {
//     .navbar {
//         position: fixed;
//     }
// }
